.fileCard {
  height: 300px;
  width: 250px;
  border-radius: 8px;
  border: 1.5px solid rgb(219, 219, 219);
  margin: 8px;
  position: relative;
  cursor: pointer;
}

.fileCardtop {
  height: 250px !important;
  border-bottom: 1px solid rgb(219, 219, 219);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(211, 211, 211, 0.18);
}
.img1 {
  height: 230px;
  width: 225px;
}
.fileCardbottom {
  display: flex;
  align-items: center;
  height: 50px;
}

.fileCardbottom p {
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
}
.imgs {
  width: 35px !important;
  height: 35px !important;
  object-fit: over !important;
  border-radius: 50%;
  margin: 0 5px;
}
.fileCardbottom:hover {
  background-color: rgba(128, 128, 128, 0.4);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.drop-file-preview__item__del {
  background-color: rgba(211, 211, 211, 0.69);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 7%;
  font-size: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.drop-file-preview__item__dow {
  background-color: rgba(211, 211, 211, 0.459);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2px;
  top: 7%;
  font-size: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.fileCard:hover .drop-file-preview__item__del {
  opacity: 1;
}

.fileCard:hover .drop-file-preview__item__dow {
  opacity: 1;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: black;
  color: white;
  font-size: 100%;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -1.9em;
  left: -2%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}
