.suspense-container {
    max-height: calc(100% - 36px); /* Adjust the 40px value according to your button height and padding */
    overflow-y: auto;
  }
  
  /* .button-container {
    position: sticky;
    bottom: 0;
    padding: 8px;
    z-index: 1;
  } */
  