.App {
  font-family: 'Almarai', sans-serif;
  text-align: center;
  }
  .ant-tabs-tab {
    color: #333 !important; /* Change the text color */
  }
  .ant-tabs-ink-bar {
    background-color: #8F6682 !important; /* Change the color to red */
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #333 !important; /* Change the text color */
    font-weight: 400;
  }
