
.filter {
  text-align: start;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 15px;
  padding: 5px;
  margin-right: 10px;
}
.filter:hover {
  background-color: rgb(193, 208, 238);
}

.filter:focus {
  background-color: rgb(154, 196, 218);
}






.HoverButton :hover {
    background:rgb(235, 130, 70);
  }
  .classColumn{
    background:rgb(235, 130, 70);
  }
  
  
  
  /* common inPROGRESS */
  .ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #08a537;
  }
  .ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 15px;
  background-color: #08a537;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  /* border: 1px solid blue; */
  margin-left: 10px;
  }
  
  /* top left*/
  .ribbon-top-left {
  top: -10px;
  left: -10px;
  /* border: 1px solid black; */
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
  }
  .ribbon-top-left::before {
  top: 0;
  right: 0;
  }
  .ribbon-top-left::after {
  bottom: 0;
  left: 0;
  }
  .ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
  /* border: 1px solid red; */
  }
  
  /* top right*/
  .ribbon-top-right {
  top: -10px;
  right: -0px;
  /* border: 1px solid green; */
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
  }
  .ribbon-top-right::before {
  top: 0;
  left: 0;
  }
  .ribbon-top-right::after {
  bottom: 0;
  right: 0;
  }
  .ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
  }
  
  /* bottom left*/
  .ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
  /* border: 1px solid orangered; */
  }
  .ribbon-bottom-left::before,
  .ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
  }
  .ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
  }
  .ribbon-bottom-left::after {
  top: 0;
  left: 0;
  }
  .ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
  }
  
  /* bottom right*/
  .ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
  }
  .ribbon-bottom-right::before,
  .ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
  }
  .ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
  }
  .ribbon-bottom-right::after {
  top: 0;
  right: 0;
  }
  .ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
  }
  
  
  
  
  
  
  
  /* common WAITING FOR APPROVAL */
  .ribbon1 {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  }
  .ribbon1::before,
  .ribbon1::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #ec1a0b;
  }
  .ribbon1 span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 15px;
  background-color: #ec1a0b;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  margin-left: 10px;
  }
  
  /* top left*/
  .ribbon1-top-left {
  top: -10px;
  left: -10px;
  }
  .ribbon1-top-left::before,
  .ribbon1-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
  }
  .ribbon1-top-left::before {
  top: 0;
  right: 0;
  }
  .ribbon1-top-left::after {
  bottom: 0;
  left: 0;
  }
  .ribbon1-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
  }
  
  /* top right*/
  .ribbon1-top-right {
  top: -10px;
  right: -10px;
  }
  .ribbon1-top-right::before,
  .ribbon1-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
  }
  .ribbon1-top-right::before {
  top: 0;
  left: 0;
  }
  .ribbon1-top-right::after {
  bottom: 0;
  right: 0;
  }
  .ribbon1-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
  }
  
  /* bottom left*/
  .ribbon1-bottom-left {
  bottom: -10px;
  left: -10px;
  }
  .ribbon1-bottom-left::before,
  .ribbon1-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
  }
  .ribbon1-bottom-left::before {
  bottom: 0;
  right: 0;
  }
  .ribbon1-bottom-left::after {
  top: 0;
  left: 0;
  }
  .ribbon1-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
  }
  
  /* bottom right*/
  .ribbon1-bottom-right {
  bottom: -10px;
  right: -10px;
  }
  .ribbon1-bottom-right::before,
  .ribbon1-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
  }
  .ribbon1-bottom-right::before {
  bottom: 0;
  left: 0;
  }
  .ribbon1-bottom-right::after {
  top: 0;
  right: 0;
  }
  .ribbon1-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
  }
  
  
  
  
  
  /*arrow tabs */
  
  
  .tabs {
  margin: 0em;
  /* border-radius: 0.25em; */
  border-left: 1px  solid lightgray;
  width:105.5%;
  height: 40px; 
  }
  
  h1 {
  margin: 0;
  border-bottom: solid;
  
  }
  
  .ulTabs {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: hidden;
  white-space: nowrap;
  }
  
  .liTabs+.liTabs {
  padding: 0.5em 2em 0.75em 1.6em;
  
  }
  
  .liTabs {
  padding: 0.63em 2.8em;
  position: relative;
  display: inline-block;
  vertical-align: top;
  }
  
  .liTabs:hover,
  .liTabs:hover+.liTabs:before,
  .liTabs.active,
  .liTabs.active+.liTabs:before {
  background: rgb(216, 242, 243);
  }
  
  .liTabs:hover:before,
  .liTabs.active:before {
  background: white;
  }
  
  .liTabs:last-child:after,
  .liTabs+.liTabs:before {
  content: '';
  position: absolute;
  top: -0.1em;
  bottom: 0.5em;
  border-right: 1px solid gray;
  border-top: 1px solid gray;
  pointer-events: none;
  box-sizing: border-box;
  box-shadow: 3px -3px 6px -3px gray;
  width: 2.68em;
  transform: rotate(30deg) skewy(30deg);
  z-index: 1;
  }
  
  .liTabs:last-child:after {
  right: -1.7em;
  background: inherit;
  }
  
  .liTabs+.liTabs:before {
  left: -2em;
  }
  
  
  /* scroll*/
  .card{
  /* width: 30%; */
  height: 800px;
  padding-bottom: 0px;
  /* border:1px solid green;  */
  }
  .scrollable{
  overflow-y: auto;
  max-height: 565px;
  /* border:1px solid ; */
  width: 100%;
  }
  
  
  
  
  .multipleLine
  {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden
  }
  
  .multipleLinePlo
  {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden
  }
  
  






















  /* camunda */


  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .highlight:not(.djs-connection) .djs-visual > :nth-child(1) {
    fill: green !important; /* color elements as green */
    stroke: yellow;
  }

  