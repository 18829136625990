.sv-body__page, .sv-body__footer {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

@import '~antd/dist/antd.css';

// Mixins

// creates the triangle appended to the rectangles in the progress bar
@mixin arrow() {
    border-bottom: 50px solid transparent;
    border-top: 50px solid transparent;
    content: " ";
    display: block;   
    height: 0;
    left: 100%;
    margin-top: -50px;
    position: absolute;
    top: 50%;   
    width: 0;
}

.progress-bar {
    list-style: none;
    overflow: hidden; // necessary to cut off top and bottom parts of triangles 
    display: flex;
    width: fit-content;
    padding-right: 50px;
    margin-bottom: 0em !important;
    padding-left: 0 !important;
    
    &__steps {
        background: #ddd;
        color: #666;
        width: 25%;
        position: relative;
        cursor: default;        
        // remove default ordered list number styles 
        list-style-image: none;
        list-style-type: none;
        padding: 20px 5px;
        text-align: center;
        
        @media screen and (min-width: 600px) {
            padding: 20px 0 20px 65px; // create padding-left to position text
            text-align: left;
        }
        
        &:first-child {
            @media screen and (min-width: 600px) {
                padding: 20px 0 20px 30px;
            }          
        }
        
        // appended triangle
        &:after {
            @media screen and (min-width: 600px) {
                @include arrow();          
                border-left: 30px solid #ddd;
                z-index: 2;
            }

        }
        
        // prepend triangle cutout to create separation border
        &:before {
            @media screen and (min-width: 600px) {
                @include arrow();            
                border-left: 30px solid #eee;
                z-index: 1;
                margin-left: 5px;
            }
        }      
    }
    
    .current {
        background: #D1CF4B;
        color: #eee;
        
        &:after {
            border-left: 30px solid #D1CF4B;
        }
    }
}

