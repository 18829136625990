.reportParagraphTitle {
  font-size: 15px;
  line-height: 1.5;
  margin: 1rem 0 1rem 0;
  font-weight: 400;
}
.reportCart {
  padding: 10px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #fafafa;
}
