.delete {
  text-align: "center";
  border-radius: 1px;
  background-color: "none";
  padding-top: 2px;
  padding-bottom: 0px;
  margin-top: 0px;
}
.delete:hover {
  background-color:lightgrey
}
